import { connect } from "react-redux";
import get from "lodash/get";
import { bindActionCreators } from "redux";
import addHours from "date-fns/addHours";
import { useCallback, useEffect } from "react";
import SmartDPSearchForm from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPSearchForm";
import { fetchSDPFilters } from "app/pages/SmartDP/smartDPActionCreators";
import PropTypes from "prop-types";
import { getTopDestinations } from "app/reducers/shopSelector";
import { QUOTATION_CODE_STATUS, RESOLUTION, SDP_PRODUCTS_FILTERS_KEY } from "app/constants";
import {
	resetAllSDPProductsFilter,
	unsetSDPProductsFilter,
} from "app/pages/SmartDP/Listing/SmartDPFilters/smartDPProductsFilterActionCreators";
import { injectIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { buildSDPSearchPayload } from "app/utils/smartDPUtils";

const SmartDPSearchFormContainer = props => {
	const { fetchSDPFilters, unsetSDPProductsFilter } = props;

	const navigate = useNavigate();

	useEffect(() => {
		fetchSDPFilters();
	}, []);

	const handleFormSubmission = useCallback(({ values, pathname = "" }) => {
		const queryParams = buildSDPSearchPayload(values);
		unsetSDPProductsFilter(SDP_PRODUCTS_FILTERS_KEY.BUDGET);
		resetAllSDPProductsFilter();

		if (pathname.includes("/sdp/listing")) {
			navigate({ pathname: pathname, search: queryParams }, { replace: true });
		} else {
			navigate({ pathname: "/sdp/listing", search: queryParams });
		}
	}, []);

	return (
		<div className="sdp-search-form__container">
			<SmartDPSearchForm {...props} onSuccess={handleFormSubmission} />
		</div>
	);
};

SmartDPSearchFormContainer.propTypes = {
	fetchSDPFilters: PropTypes.func,
	unsetSDPProductsFilter: PropTypes.func,
	isMobile: PropTypes.bool,
	brand: PropTypes.string,
	disableFormPersistance: PropTypes.bool, // used to avoid fields flickering when coming from merch page with sdp form to home sdp
};

const mapStateToProps = (state, props) => {
	const booking = state.booking;

	const initialValues = {
		travelDates: {
			departureDate: "",
			endDate: "",
		},
	};

	if (get(state, "smartDP.cabins[0]")) {
		initialValues.cabin = get(state, "smartDP.cabins[0]");
	}

	if (booking.cabin?.code) {
		initialValues.cabin = booking.cabin;
	}

	if (
		!props.disableFormPersistance &&
		booking.destinationResort?.code &&
		state.smartDP.destinations
	) {
		const destination = state.smartDP.destinations.find(
			destination => destination.code.toString() === booking.destinationResort.code
		);
		if (destination) {
			initialValues.destinationResort = {
				...booking.destinationResort,
				departureDateStart: destination.departureDateStart,
				departureDateEnd: destination.departureDateEnd,
			};
		}
	}

	if (!props.disableFormPersistance && booking.departureCity?.code) {
		initialValues.departureCity = booking.departureCity;
	}

	if (!props.disableFormPersistance && booking.departureDate) {
		// @see SmartDPSearch in handleFormSubmission
		// ex Paris : Fri Jun 11 2021 02:00:00 GMT+0200 (heure d’été d’Europe centrale)
		let departureDate = new Date(booking.departureDate);
		// => Fri Jun 11 2021 00:00:00 GMT+0200 (heure d’été d’Europe centrale)
		departureDate = addHours(departureDate, departureDate.getTimezoneOffset() / 60);
		// departureDate.geTime() gives 10/6/2021 à 22:00:00
		initialValues.travelDates.departureDate = departureDate;
	}

	if (!props.disableFormPersistance && booking.endDate) {
		// @see SmartDPSearch in handleFormSubmission
		// ex Paris : Fri Jun 11 2021 02:00:00 GMT+0200 (heure d’été d’Europe centrale)
		let endDate = new Date(booking.endDate);
		// => Fri Jun 11 2021 00:00:00 GMT+0200 (heure d’été d’Europe centrale)
		endDate = addHours(endDate, endDate.getTimezoneOffset() / 60);
		// endDate.geTime() gives 10/6/2021 à 22:00:00
		initialValues.travelDates.endDate = endDate;
	}

	if (booking.occupancies?.length > 0) {
		initialValues.occupancies = booking.occupancies;
	}

	if (!props.disableFormPersistance && booking.duration?.value) {
		initialValues.duration = {
			code: booking.duration.value,
		};
	}

	return {
		initialValues,
		destinations: state.smartDP.destinations,
		departureCities: state.smartDP.departureCities,
		cabins: state.smartDP.cabins,
		validateOnMount: state.smartDP.status === QUOTATION_CODE_STATUS.BAD_REQUEST,
		topDestinationsList: getTopDestinations(state) || [],
		isMobile: [RESOLUTION.SMALL, RESOLUTION.MEDIUM].includes(state?.resolution),
		brand: state.brand.code,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			fetchSDPFilters,
			resetAllSDPProductsFilter,
			unsetSDPProductsFilter,
		},
		dispatch
	);
};

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SmartDPSearchFormContainer)
);
